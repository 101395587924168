<template>
  <!-- Project Details Area Start -->
  <section class="ekifa-project-details-area section_80">
    <b-container>
      <b-row>
        <b-col md="9" class="no-pad-right">
          <div class="single-project-image-bg">
            <img :src="require('../assets/img/project_details_big.jpeg')" alt="project big image" />
          </div>
        </b-col>
        <b-col md="3">
          <div class="single-project-image-sm">
            <div class="single-project-image-item">
              <img :src="require('../assets/img/pro_service.jpg')" alt="project" />
            </div>
            <div class="single-project-image-item">
              <img :src="require('../assets/img/pro_service_2.jpg')" alt="project" />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5" lg="4">
          <div class="project-description">
            <h3>Project Description</h3>
            <div class="project-description-box">
              <ul>
                <li><span>Client</span>: John Doe</li>
                <li><span>Location</span>: 121 King Street, Australia</li>
                <li><span>Category</span>: Finance & Legal</li>
                <li><span>Date</span>: 21 january, 2019</li>
                <li><span>Value</span>: $550.000  </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col md="7" lg="8">
          <div class="single_project_wrap_left">
            <h3>project title here</h3>
            <p>Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. </p>
            <p> vulputate turpis tincidunt. Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. Aenean suscipit eget mi act fermentum phasellus vulputate turpis tincidunt. </p>
            <ul>
              <li><i class="fa fa-check"></i> Aenean suscipit eget mi act fermentum</li>
              <li><i class="fa fa-check"></i> fermentum phasellus vulputate turpis tincidunt</li>
              <li><i class="fa fa-check"></i> phasellus vulputate turpis tincidunt</li>
              <li><i class="fa fa-check"></i> phasellus vulputate turpis tincidunt</li>
            </ul>
            <ul>
              <li><i class="fa fa-check"></i> enean suscipit eget mi</li>
              <li><i class="fa fa-check"></i> ermentum phasellus turpis</li>
              <li><i class="fa fa-check"></i> hasellus vulputate tincidunt</li>
              <li><i class="fa fa-check"></i> hasellus ulputate turpis </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Project Details Area End -->
</template>

<script>
export default {
  name: "ProjectDetailsArea"
}
</script>

<style scoped>

</style>