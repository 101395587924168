<template>
  <!-- Project Work Area Start -->
  <section class="ekifa-project-work-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="project-work-box">
            <h3>Interested in work with us?</h3>
            <p>Nullam vulputate lorem ut leo. Sed volutpat. Etiam non pede. Nullam et mauris. Praesent sed elit. Nulla posuere. Etiam sit amet turpis. Nullam mattis libero non</p>
            <b-link class="ekifa-btn">Hire Us Now</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Project Work Area End -->
</template>

<script>
export default {
  name: "HireArea"
}
</script>

<style scoped>

</style>