<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Project Details Area Component -->
  <project-details-area/>
  <!-- Hire Area Component -->
  <hire-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import ProjectDetailsArea from "../../components/ProjectDetailsArea";
import HireArea from "../../components/HireArea";
export default {
  name: "ProjectDetails",
  components: {HireArea, ProjectDetailsArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: 'Project Details',
        pageTitle: 'Project Details',
      }
    }
  }
}
</script>

<style scoped>

</style>